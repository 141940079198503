:root {
  --primary-color: #df37a5;
  --secondary-color: rgb(232, 222, 248);
  --error-color: #d32f2f;
  --success-color: #2e7d32;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3edf7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Menu overrides */
ul[role="menu"] a,
ul[role="menu"] a:hover,
ul[role="menu"] a:visited,
ul[role="menu"] a:focus {
  text-decoration: none;
  color: inherit;
}

ul[role="menu"] a.active .MuiListItemIcon-root {
  color: var(--primary-color);
}

ul[role="menu"] .MuiListItemIcon-root {
  transition: color, 0.3s;
}

.MuiDivider-root {
  border-color: #eee;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}