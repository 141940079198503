.whale-blur {
    background: rgba(207, 158, 199, 0.08);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(8.8px);
}

.whale-container-inner {
    padding-top: 4%;
    display: block;
    height: 100%;
}

.whale-stats-row-1 {
    height: 450px;
    justify-content: space-between;
    display: flex;
}

.whale-stats-row-2 {
    margin-top: 2%;
    display: flex;
    justify-content:flex-start;
    height: 450px;
    margin-bottom: 2%;
}

.whale-list {
    width: 20%;
    height: inherit;
    /* border: 1px solid black; */
    border-radius: 4%;
    overflow-y: scroll;
    overflow-x: clip;
}

.whale-coin-summary {
    padding-inline-start: 4%;
    display: block;
    /* justify-content: center; */
    /* align-items: center; */
    width: 35%;
    height: inherit;
    /* border: 1px solid black; */
    border-radius: 4%;
}

.whale-coin-extra {
    display: block;
    width: 30%;
    height: inherit;
    
}

.biggest-hold{
    display: flex;
    height: 100%;
}

.coin-table {
    padding: 3%;
    display: block;
    height: 96%;
    border-radius: 4%;


    overflow-y: scroll;
    overflow-x: clip;
   
}

.whale {
    padding: 5%;
    margin-left: 3%;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 50px;

    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}

.whale:hover {
    cursor: pointer;
    /* slighly increase size on hover */
    transform: scale(1.05);
    
    /* have a cradient background  */
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.6px);
    -webkit-backdrop-filter: blur(6.6px);
    transition: 0.2s ease-in-out;
}

.whale-address {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 1.2rem;
    font-weight: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
}

.whale-transaction-history {
    width: 65%;
    height: inherit;
    /* border: 1px solid black; */
    border-radius: 4%;
}

.whale-transaction-history-table {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}


.whale-transaction-history-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0% 5% 0% 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.whale-transaction-history-row p {

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}