/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&family=Inter:wght@400;500&family=Merriweather:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&family=Caveat:wght@700&display=swap');

/* .DraftEditor-root {
  font-family: "Inter";
  font-size: 20px;
  line-height: 26px;
} */

.blur-container{
  padding: 0% 5% 0% 5%;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  height: 100%; 
}

.fetch-logo {
  font-family: "Caveat";
  font-weight: 700;
  font-size: xx-large;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.voted {
  animation: Voted;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.error {
  animation: LikeError;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@keyframes Voted {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, -10px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes LikeError {
  0% {
    transform: translate(0px, 0px);
  }

  25% {
    transform: translate(10px, 0px);
  }

  50% {
    transform: translate(-10px, 0px);
  }

  75% {
    transform: translate(10px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:-webkit-any-link {
  text-decoration: unset;
  /* color: unset; */
}

.link {
  color: var(--primary-color);
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

iframe {
  width: 100%;
  /* height: 520px; */
}

.clickable-header .MuiAvatar-root {
  cursor: pointer;
  transition: all ease 0.2s; 
}

.clickable-header .MuiAvatar-root:hover {
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
}

.clickable-header .MuiCardHeader-title {
  cursor: pointer;
}

.clickable-header .MuiCardHeader-title:hover {
  text-decoration: underline;
}

@keyframes anim-poll-in {
  from {width: 0%;}
  to {width: 100%;}
}

.poll-animation {
  animation-name: anim-poll-in;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.05);
  white-space: nowrap;
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

@media (max-width: 500px) {
  .simple-layout-logo {
    height: 140px;
  }
}

.sharethis-container {
  padding: 2%;
}

.portfolio-display-container { 
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #df37a5 1px solid;

}

.portfolio-display {
    width: 100%;
    height: 100%;
    padding: 4%;
}

.portfolio-display-header {
    position:relative;
    display: flex;
    justify-content: space-between;
}

.portfolio-display-header-address {
    position: relative;
    width: clamp(300px, 500px, 800px)
}

.portfolio-display-content-inner-token {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}

.portfolio-column {
    display: block;
    /* width: 100%; */
    width: 100px;
    overflow: hidden;
}
.portfolio-display-content-inner-token-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5%;
    width: 100px;
    
}
.portfolio-display-content-inner-token-info-description {
  width: 100px;
}

.portfolio-display-content-inner-token-info img {
    width: 30px;
    height: 30px;
    margin-right: 10%;
}


.token-name{
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom:0px !important;
    text-align: start;
}

.token-description {
    margin-top: 0px !important;
    font-size: 1rem;
    font-weight: 240;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.loader:before,
  .loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
}

.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem #fff;
  animation-name: pulsIn;
}

.loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #fff;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #fff;
    opacity: 1;
  }

  50%, 100% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #fff;
    opacity: 0;
  }

  100% {
    box-shadow: 0 0 0 1rem #fff;
    opacity: 1;
  }
}
      